.career-highlight_timeline {
  grid-column-start: 1;
  grid-column-end: 2;
  position: relative;
}

.career-highlight_info {
  grid-column-start: 2;
  grid-column-end: 11;
  padding-top: 0.375rem;
  padding-bottom: 1rem;
}

.career-highlight_timeline-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.career-highlight_timeline-icon svg {
  font-size: 1.5rem;
}

.career-highlight_timeline-line {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: black;
  left: 1.5rem;
  z-index: 1;
}

.career-highlight_timings,
.career-highlight_description {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 760px) {

  .career-highlight_info {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 600px) {

  .career-highlight_timeline-icon {
    width: 2rem;
    height: 2rem;
  }

  .career-highlight_timeline-icon svg {
    font-size: 1rem;
  }

  .career-highlight_timeline-line {
    left: 1rem;
  }

  .career-highlight_info {
    padding-top: 0.125rem;
  }
}
