/* 
   CSS Reset
   http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

:root {
  --light-theme-bg: #eeeff1;
  --light-theme-text: #011502;
  --dark-theme-bg: #203139;
  --dark-theme-text: #f1e9db;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 1rem;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

html {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

body {
  margin: 0;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.theme {
  transition: background-color .3s 0s ease-in-out,
    color .3s 0s ease-in-out;
}

.theme .career-highlight_timeline-icon {
  transition: background-color .3s 0s ease-in-out,
    border-color .3s 0s ease-in-out;
}

.theme .career-highlight_timeline-line {
  transition: background-color .3s 0s ease-in-out;
}

.theme a {
  transition: color .3s 0s ease-in-out;
}

.theme .tag,
.theme .header_theme-toggle .toggle {
  transition: border-color .3s 0s ease-in-out;
}

.light-theme,
.light-theme .career-highlight_timeline-icon {
  background-color: var(--light-theme-bg);
}

.light-theme,
.light-theme a {
  color: var(--light-theme-text);
}

.light-theme .career-highlight_timeline-line,
.light-theme .header_theme-toggle .toggle:after {
  background-color: var(--light-theme-text);
}

.light-theme .career-highlight_timeline-icon,
.light-theme .tag,
.light-theme .header_theme-toggle .toggle {
  border-color: var(--light-theme-text);
}

.light-theme a:hover {
  opacity: .7;
}

.dark-theme,
.dark-theme .career-highlight_timeline-icon {
  background-color: var(--dark-theme-bg);
}

.dark-theme,
.dark-theme a {
  color: var(--dark-theme-text);
}

.dark-theme .career-highlight_timeline-line,
.dark-theme .header_theme-toggle .toggle:after {
  background-color: var(--dark-theme-text);
}

.dark-theme .career-highlight_timeline-icon,
.dark-theme .tag,
.dark-theme .header_theme-toggle .toggle {
  border-color: var(--dark-theme-text);
}

.dark-theme a:hover {
  opacity: .7;
}

.page-container {
  padding-top: 5rem;
}

.wrapper {
  max-width: 1140px;
  padding: 0 30px;
  margin: 0 auto;
}

.grid {
  display: grid;
  column-gap: 50px;
}

.grid-10 {
  grid-template-columns: repeat(10, 1fr);
}

.grid-align-center {
  align-items: center;
}

p {
  line-height: 1.5;
}

h1 {
  font-size: 4rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.text-bigger {
  font-size: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: 500;
}

.section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media screen and (max-width: 1140px) {

  html {
    font-size: 18px;
  }

  .section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 1000px) {

  h1 {
    font-size: 3rem;
  }

  .grid {
    column-gap: 2rem;
  }
}

@media screen and (max-width: 760px) {

  html {
    font-size: 16px;
  }

  .section {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  h1 {
    margin-bottom: 1rem;
  }

  h2 {
    text-align: center;
  }

  .text-bigger {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 600px) {
  .grid {
    column-gap: 1rem;
  }

  .wrapper {
    padding: 0 1rem;
  }
}
