.about-me_description {
  grid-column-start: 1;
  grid-column-end: 7;
}

.about-me_description__text {
  margin-top: 0.5rem;
}

.about-me_tags {
  padding-top: 3rem;
  grid-column-start: 7;
  grid-column-end: 11;
}

@media screen and (max-width: 760px) {

  .about-me_description,
  .about-me_tags {
    grid-column-start: 1;
    grid-column-end: 11;
  }

  .about-me_tags {
    padding-top: 1.5rem;
  }
}
