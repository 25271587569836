.tag {
  display: inline-block;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 10px;
  font-weight: 500;
}

@media screen and (max-width: 600px) {

  .tag {
    font-size: 0.8rem;
    padding: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
  }
}
