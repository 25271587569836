.header {
  height: 4rem;
  position: fixed;
  width: 100%;
  padding: 1rem 0;
  background-color: inherit;
  z-index: 3;
}

.header_container,
.header_links {
  display: flex;
  justify-content: space-between;
}

.header_logo {
  font-size: 2rem;
  line-height: 2rem;
  cursor: default;
}

.header_links ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header_link {
  margin-right: 1rem;
  line-height: 2rem;
}

.header_theme-toggle {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.header_theme-toggle .toggle {
  display: inline-block;
  width: 2em;
  height: 1em;
  border: 2px solid black;
  border-radius: 0.5em;
  position: relative;
  margin: 0 0.5rem;
  cursor: pointer;
}

.header_theme-toggle .toggle:after {
  content: "";
  display: block;
  width: calc(50% + 2px);
  height: calc(100% + 2px);
  border-radius: 50%;
  position: absolute;
  top: -1px;
  background-color: black;
}

.header_theme-toggle.header_theme-toggle_light .toggle:after {
  left: -1px;
}

.header_theme-toggle.header_theme-toggle_dark .toggle:after {
  left: calc(50% - 1px);
}

@media screen and (max-width: 600px) {

  .header_links ul {
    display: none;
  }

  .header_theme-toggle {
    font-size: 1.5rem;
  }
}
